import isEmpty from 'lodash/isEmpty';
import lozad from 'lozad';

import browser from '../../helpers/browser';
import { doIEObjectFitPolyfill } from '../../polyfills';
import dom from '../../wrapper/DomWrapper';
import imageErrorHandler from '../Image';
import { setImageBorderRadius } from '../Image/BorderRadius';

import { getLoadImageConfig } from './utils';

const isIE = browser.isIe();

const onLoadImage = ({ target }) => {
  setImageBorderRadius(target);
};

export const lazy = lozad('.lazy', {
  ...getLoadImageConfig(onLoadImage),
  loaded(el) {
    if (isIE && el.tagName === 'IMG') setTimeout(() => doIEObjectFitPolyfill(el), 0);

    if (el.tagName !== 'PICTURE') return;

    const image = dom.getElement('img', el);

    if (!image) return;

    const classes = (el.dataset.classnames || '').split(' ');
    const { alt } = el.dataset;
    const { title } = el.dataset;

    if (!isEmpty(classes)) classes.forEach((className) => dom.addClass(image, className));

    if (alt) image.setAttribute('alt', alt);

    if (title) image.setAttribute('title', title);

    imageErrorHandler();
  },
});

export default (el) => {
  lazy.observe(el);

  const coolImage = dom.getCollection('.lazy:not([data-loaded="true"])');

  if (!coolImage) return;

  [...coolImage].forEach((image) => {
    lazy.triggerLoad(image);
  });
};
