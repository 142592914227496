import isArray from 'lodash/isArray';

import { IN_VIEW_CLASS_NAME, IN_VIEW_WAIT_CLASS_NAME } from '../../constants';
import dom from '../wrapper/DomWrapper';

const requestAnimFrameFn = dom.window.requestAnimationFrame
  || dom.window.webkitRequestAnimationFrame
  || dom.window.mozRequestAnimationFrame
  || dom.window.oRequestAnimationFrame
  || dom.window.msRequestAnimationFrame
  // eslint-disable-next-line func-names
  || function (callback) {
    dom.window.setTimeout(callback, 1000 / 60);
  };

export const updateInView = () => {
  const customEvent = new Event('updateInView');

  dom.window.dispatchEvent(customEvent);
};

/**
 *
 * @param {HTMLElement[]|HTMLElement} elements
 * @param {boolean=} withUpdate
 */
export const connectInView = (elements, withUpdate = true) => {
  const arrElements = isArray(elements) ? elements : [elements];
  const customEvent = new Event('connectInView');

  customEvent.elements = arrElements;

  dom.window.dispatchEvent(customEvent);

  if (withUpdate) updateInView();
};

/**
 *
 * @param {HTMLElement[]|HTMLElement} elements
 */
export const addInViewWait = (elements) => {
  const arrElements = isArray(elements) ? elements : [elements];

  for (const el of arrElements) {
    dom.addClass(el, IN_VIEW_WAIT_CLASS_NAME);
    dom.addClass(el, IN_VIEW_CLASS_NAME);
  }

  connectInView(arrElements, false);
};

/**
 *
 * @param {HTMLElement[]|HTMLElement} elements
 */
export const removeInViewWait = (elements) => {
  const arrElements = isArray(elements) ? elements : [elements];

  for (const el of arrElements) {
    dom.removeClass(el, IN_VIEW_WAIT_CLASS_NAME);
  }

  updateInView();
};

/**
 *
 * @param {string} selector
 * @param {function=} onEnter
 * @param {function=} onExit
 * @param {number=} translateGap
 * @param {boolean=} once
 */
const inView = ({
  selector,
  onEnter = () => {},
  onExit = () => {},
  translateGap = 75,
  once = false,
}) => {
  let ticking = false;
  let elements = [];
  let windowHeight = 0;

  const updateData = () => {
    windowHeight = dom.windowHeight;
  };

  const updateScroll = () => {
    ticking = false;

    const { documentScrollTop, bodyHeight } = dom;
    const isScrollEnd = Math.round(documentScrollTop + windowHeight) >= Math.round(bodyHeight);

    elements = elements.filter((el) => {
      const { top, bottom } = el.getBoundingClientRect();
      const [translateTop, translateBottom] = [top - translateGap, bottom - translateGap];
      const topEntered = translateTop >= 0 && translateTop <= windowHeight;
      const bottomEntered = translateBottom >= 0 && translateBottom <= windowHeight;
      const elOverTarget = translateTop <= 0 && translateBottom >= windowHeight;
      const entered = (topEntered || bottomEntered || elOverTarget)
        || (isScrollEnd && translateTop >= windowHeight);

      if (dom.hasClass(el, IN_VIEW_WAIT_CLASS_NAME)) return true;

      // enter + exit
      if (entered && !el.evEntered) {
        onEnter(el);
        if (once) return false;
      } else if (!entered && el.evEntered) {
        onExit(el);
      }

      // eslint-disable-next-line no-param-reassign
      el.evEntered = entered;

      return true;
    });

    // eslint-disable-next-line no-use-before-define
    if (elements.length === 0) removeEvents();
  };

  const onScroll = () => {
    if (ticking) return false;

    ticking = true;
    requestAnimFrameFn(updateScroll);
  };

  const onResize = () => {
    updateData();
    updateScroll();
  };

  const onLoad = () => {
    updateData();
    updateScroll();
  };

  const onConnect = (e) => {
    const { elements: newElements = [] } = e;

    // eslint-disable-next-line no-use-before-define
    if (elements.length === 0) setupEvents();

    for (const el of newElements) {
      elements.push(el);
    }
  };

  const onUpdate = () => {
    updateData();
    updateScroll();
  };

  const setupElements = () => {
    const collection = dom.getCollection(selector) || [];

    elements = [...collection];
  };

  const setupEvents = () => {
    dom.on(dom.window, 'resize', onResize);
    dom.on(dom.window, 'scroll', onScroll);
  };

  const removeEvents = () => {
    dom.off(dom.window, 'resize', onResize);
    dom.off(dom.window, 'scroll', onScroll);
  };

  const setupCustomEvents = () => {
    dom.on(dom.window, 'connectInView', onConnect);
    dom.on(dom.window, 'updateInView', onUpdate);
  };

  const init = () => {
    if (!selector) {
      console.error('must pass a selector');

      return false;
    }

    setupElements();
    setupCustomEvents();

    if (elements.length === 0) {
      console.error('no selector elements found');

      return false;
    }

    setupEvents();
    onLoad();
  };

  init();
};

export default inView;
