import {
  DESKTOP,
  LAPTOP,
  PHONE,
  QUAD,
  TABLET,
  ULTRA,
} from '../../../device';

const LAYOUT_TYPE = {
  grid: 'grid',
  masonry: 'masonry',
};

export const DEVICE_SIZES = {
  [PHONE]: [0, 320],
  [TABLET]: [321, 1024],
  [LAPTOP]: [1025, 1366],
  [DESKTOP]: [1367, 1920],
  [QUAD]: [1921, null],
};

export const FLUID_DEVICE_SIZES = {
  [QUAD]: [1921, 2560],
  [ULTRA]: [2561, null],
};

export const DEFAULT_GALLERY_HOVER_EFFECT = {
  enabled: false,
  value: '_effect_scale',
};

export default LAYOUT_TYPE;
