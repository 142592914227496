import findIndex from 'lodash/findIndex';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import isNil from 'lodash/isNil';
import remove from 'lodash/remove';

import ecommerce from '../../../../../ecommerce/ecwid/custom';
import router from '../../../../../ecommerce/ecwid/custom/router';
import { PRODUCT_PAGE_WRAPPER_ID } from '../../../../../ecommerce/ecwid/custom/router/constants';
import checkProjectWithAnimation from '../../../../../helpers/checkProjectWithAnimation';
import { addInViewWait, removeInViewWait } from '../../../../../helpers/inView';
import dom from '../../../../../wrapper/DomWrapper';
import WidgetWrapper from '../../../../../wrapper/WidgetWrapper';
import GalleryLightBox from '../../../../Gallery';
import lazyLoad from '../../../../LazyLoad';
import extendShemaOrg from '../../../../SchemaOrg';
import { getImageSize } from '../utils';

import {
  CAROUSEL_ITEM_TEMPLATE,
  PRODUCT_BADGES_TEMPLATE,
  PRODUCT_BUY_BUTTON_ID,
  PRODUCT_CAROUSEL_ID,
  PRODUCT_CAROUSEL_LIGHT_SELECTOR,
  PRODUCT_CHECKOUT_BUTTON_ID,
  PRODUCT_DESCRIBE_ID,
  PRODUCT_GALLERY_ID,
  PRODUCT_NO_PREVIEW_ID,
  PRODUCT_OPTION_TYPES,
  PRODUCT_OPTIONS_ID,
  PRODUCT_PREVIEW_ID,
  PRODUCT_PREVIEW_LIGHT_SELECTOR,
  PRODUCT_PREVIOUS_PRICE_ID,
  PRODUCT_QUANTITY_IN_CART_ID,
  PRODUCT_QUANTITY_INPUT_ID,
  PRODUCT_REGULAR_PRICE_ID,
  PRODUCT_RETRY_BUTTON_ID,
  PRODUCT_RETRY_ID,
  PRODUCT_SAVED_PERCENT_ID,
  PRODUCT_SIDEBAR_ID,
  PRODUCT_SOLD_OUT_ID,
  PRODUCT_SPINNER_ID,
  PRODUCT_STATUS_ID,
  PRODUCT_TITLE_ID,
} from './constants';
import ProductOptions from './ProductOptions';

class Product extends WidgetWrapper {
  id = null;

  imageSize = 0;

  state = {
    isRetry: false,
    product: null,
    order: {
      quantityToAdd: 1,
      options: {},
    },
  };

  init = async ({ id }) => {
    this.id = id;

    const elProductContainer = dom.getElement(`#${PRODUCT_PAGE_WRAPPER_ID}`);
    const elRetryButton = dom.getElement(`#${PRODUCT_RETRY_BUTTON_ID}`);
    const elCheckoutButton = dom.getElement(`#${PRODUCT_CHECKOUT_BUTTON_ID}`);
    const section = elProductContainer?.closest('.s-section');

    if (section) {
      section.removeAttribute('style');
      dom.removeElement(dom.getElement('.section_bg', section));
    }

    elProductContainer?.closest('.s-section')?.removeAttribute('style');
    dom.on(elRetryButton, 'click', this.handleRetryButton);
    dom.on(elCheckoutButton, 'click', this.handleCheckoutButton);

    this.withAnimation = checkProjectWithAnimation();
    this.imageSize = getImageSize(dom.getElementWidth(elProductContainer));
    this.elGallery = dom.getElement(`#${PRODUCT_GALLERY_ID}`, elProductContainer);
    this.elSidebar = dom.getElement(`#${PRODUCT_SIDEBAR_ID}`, elProductContainer);

    this.initAnimation();

    await this.fetchProduct({
      // eslint-disable-next-line consistent-return
      onSuccess: () => {
        this.initSidebar();
        this.initGallery();
        this.initQuantityInCart();
        this.initBuyButton();
        this.initOptions();
        this.initQuantityInput();

        if (this.checkOutOfStock()) return this.viewState.productOutOfStock();

        this.initSchemaOrg();

        this.viewState.productLoadedSuccess();
      },
      onError: this.viewState.productLoadedError,
    });
  };

  initSchemaOrg = () => {
    const {
      name, price, compareToPrice, url, sku, description, condition, images, inStock, quantity,
    } = this.state.product;

    extendShemaOrg('Product', ['@context'], 'https://schema.org/');
    extendShemaOrg('Product', ['name'], name);
    extendShemaOrg('Product', ['image'], images.map((image) => image.imageOriginalUrl));
    extendShemaOrg('Product', ['sku'], sku);

    if (description) {
      const descriptionWithoutTags = description.replace(/<\/?[^>]+(>|$)/g, '');

      extendShemaOrg('Product', ['description'], descriptionWithoutTags);
    }

    extendShemaOrg('Product', ['offers', '@type'], 'Offer');
    extendShemaOrg('Product', ['offers', 'price'], price.toString());
    extendShemaOrg('Product', ['offers', 'url'], url);
    extendShemaOrg(
      'Product',
      ['offers', 'availability'],
      inStock ? 'https://schema.org/InStock' : 'https://schema.org/OutOfStock'
    );
    extendShemaOrg('Product', ['offers', 'itemCondition'], condition !== 'NEW' ? 'https://schema.org/UsedCondition' : 'https://schema.org/NewCondition');

    if (compareToPrice) {
      extendShemaOrg('Product', ['offers', 'offers', '@type'], 'AggregateOffer');
      extendShemaOrg('Product', ['offers', 'offers', 'highPrice'], compareToPrice.toString());
      extendShemaOrg('Product', ['offers', 'offers', 'lowPrice'], price.toString());

      if (quantity) extendShemaOrg('Product', ['offers', 'offers', 'offerCount'], quantity.toString());
    }
  };

  fetchProduct = async ({
    onSuccess = () => null,
    onError = () => null,
  } = {}) => {
    this.viewState.productLoading();

    try {
      this.state.product = await ecommerce.provider.getProduct(this.id);
      onSuccess();
    } catch {
      onError();
    }
  };

  calculatePrice = () => {
    const orderOptions = get(this, ['state', 'order', 'options'], {});

    Object.keys(orderOptions).forEach(
      (optionName) => {
        const productOption = get(this, ['state', 'product', 'options'], []);
        const productOptionIndex = findIndex(productOption, { name: optionName });
        const productOptionType = get(productOption, [productOptionIndex, 'type'], null);

        if (productOptionType === PRODUCT_OPTION_TYPES.CHECKBOX) {
          const productOptionChoice = get(productOption, [productOptionIndex, 'choices'], []);

          productOptionChoice.forEach((choice, index) => {
            const text = get(choice, ['text']);
            const isChecked = orderOptions[optionName].includes(text);

            this.state.product.options[productOptionIndex].choices[index] = {
              ...choice,
              default: isChecked,
            };
          });

          return;
        }

        const productOptionChoice = get(productOption, [productOptionIndex, 'choices'], []);
        const choiceIndex = findIndex(
          productOptionChoice,
          { text: orderOptions[optionName] }
        );

        if (choiceIndex === -1) return;

        this.state.product.options[productOptionIndex] = {
          ...this.state.product.options[productOptionIndex],
          defaultChoice: choiceIndex,
        };
      }
    );
  };

  updatePrice = async () => {
    this.calculatePrice();

    const elRegularPrice = dom.getElement(`[id="${PRODUCT_REGULAR_PRICE_ID}"]`);
    const elPreviousPrice = dom.getElement(`[id="${PRODUCT_PREVIOUS_PRICE_ID}"]`);
    const elSavedPercent = dom.getElement(`[id="${PRODUCT_SAVED_PERCENT_ID}"]`);

    const price = ecommerce.provider.getPrice(
      this.state.product.price,
      1,
      this.state.product.options
    );

    const previousPrice = ecommerce.provider.getPreviousPrice(
      this.state.product.price,
      this.state.product.compareToPrice,
      1,
      this.state.product.options
    );

    const savedPercent = ecommerce.provider.getSavedPercent(price, previousPrice);
    const formatAndUnits = await ecommerce.provider.getFormatAndUnits();

    dom.addText(elRegularPrice, `${ecommerce.provider.formatPrice(price, formatAndUnits)}`);
    dom.addText(
      elPreviousPrice,
      previousPrice ? `${ecommerce.provider.formatPrice(previousPrice, formatAndUnits)}` : ''
    );
    dom.addText(elSavedPercent, savedPercent ? `Save ${savedPercent}%` : '');
    extendShemaOrg('Product', ['offers', 'priceCurrency'], formatAndUnits?.currency);
  };

  initSidebar = () => {
    try {
      const elTitle = dom.getElement(`[id="${PRODUCT_TITLE_ID}"] h1`);
      const elDescription = dom.getElement(`[id="${PRODUCT_DESCRIBE_ID}"]`);

      dom.addText(elTitle, this.state.product.title);

      if (this.state.product.description) {
        dom.updateStyle(elDescription, { display: 'block' });
        dom.addHtml(
          dom.getElement('.product-page__description-text', elDescription),
          this.state.product.description
        );
      }

      this.updatePrice();
    } catch {
      this.viewState.productLoadedError();
    }
  };

  initGallery = () => {
    this.initPreview();
    this.initCarousel();
  };

  // eslint-disable-next-line consistent-return
  initPreview = () => {
    const { images } = this.state.product;
    const thumbnail = this.getPreviewImage(images);

    if (!thumbnail) return this.viewState.galleryWithoutPreview();

    this.viewState.galleryWithPreview(thumbnail);
    this.preparePreviewLightBox(thumbnail);
  };

  initAnimation = () => {
    if (!this.withAnimation) return;

    const elements = [];

    if (this.elGallery) elements.push(this.elGallery);
    if (this.elSidebar) elements.push(this.elSidebar);

    if (elements.length === 0) return;

    addInViewWait(elements);
  };

  updateAnimation = (el) => {
    if (!this.withAnimation || !el) return;

    removeInViewWait(el);
  };

  getFallbackImageSize = (image) => Object.keys(image).filter((key) => key.startsWith('image')).pop();

  getPreviewImage = (images) => {
    if (isEmpty(images)) return null;

    const image = images.find(({ isMain }) => isMain) || images[0];

    return image[this.imageSize] || image[this.getFallbackImageSize(image)];
  };

  // eslint-disable-next-line consistent-return
  preparePreviewLightBox = (image) => {
    if (!image) return null;

    const elPreviewLightBox = dom.getElement(PRODUCT_PREVIEW_LIGHT_SELECTOR);
    const indexInGallery = findIndex(
      this.state.product.images, (currentImage) => currentImage[this.imageSize] === image
          || currentImage[this.getFallbackImageSize(currentImage)] === image
    );

    if (indexInGallery === -1) return null;

    dom.on(elPreviewLightBox, 'click', this.handleClickPreview(indexInGallery));
  };

  initCarousel = () => {
    const elCarouselContainer = dom.getElement(`#${PRODUCT_CAROUSEL_ID}`);
    const { images } = this.state.product;
    const items = this.getCarouselImages(images);

    if (!items) return;

    const itemsTemplate = items.reduce(
      (templates, imageSrc, index) => templates + CAROUSEL_ITEM_TEMPLATE(
        imageSrc,
        `product-carousel-${index}`
      ), ''
    );

    dom.addHtml(
      dom.getElement('.gallery-wrap', elCarouselContainer),
      itemsTemplate
    );

    this.prepareCarouselLightBox(items);
    lazyLoad();
  };

  // eslint-disable-next-line consistent-return
  prepareCarouselLightBox = (images) => {
    if (!images) return null;

    const galleryLightBox = new GalleryLightBox(PRODUCT_CAROUSEL_LIGHT_SELECTOR);
    const elCarouselLightBox = dom.getElement(PRODUCT_CAROUSEL_LIGHT_SELECTOR);
    const settings = images.reduce((acc, imageSrc, index) => {
      acc[`product-carousel-${index}`] = {
        src: imageSrc,
        // eslint-disable-next-line camelcase
        lightbox_theme: '_dark',
      };

      return acc;
    }, {
      hasLightbox: true,
    });

    elCarouselLightBox.dataset.settings = JSON.stringify(settings);

    galleryLightBox.init();
  };

  getCarouselImages = (images) => {
    if (!images) return null;

    return images.map((image) => image[this.imageSize] || image[this.getFallbackImageSize(image)]);
  };

  handleRetryButton = () => {
    this.init({ id: this.id });
  };

  handleCheckoutButton = () => {
    router.goToCartPage();
  };

  initQuantityInCart = async () => {
    let quantity;

    try {
      quantity = await ecommerce.provider.cart.itemGetQuantity(this.id);
    } catch {
      quantity = 0;
    }

    this.setQuantityInCart(quantity);
    this.checkCheckoutButton(quantity);

    ecommerce.provider.cart.onChange.add(this.handleQuantityInCartChange);
  };

  setQuantityInCart = (quantity) => {
    const elInBag = dom.getElement(`#${PRODUCT_QUANTITY_IN_CART_ID}`);
    const elQuantityInput = dom.getElement(`#${PRODUCT_QUANTITY_INPUT_ID}`);

    const availableItemsCount = this.state.product.quantity - quantity;
    const pluralItem = quantity > 1 ? 'items' : 'item';
    const inBagText = quantity >= 1
      ? `${quantity} ${pluralItem} in the cart`
      : '';
    const outOfStockText = this.checkLeftInStockMore(quantity)
      ? ', no more left in stock'
      : '';

    dom.addText(elInBag, `${inBagText}${outOfStockText}`);
    elQuantityInput.setAttribute('max', availableItemsCount.toString());
  };

  checkLeftInStockMore = (quantity) => quantity >= this.state.product.quantity;

  handleQuantityInCartChange = ({ items = [] }) => {
    const quantity = items.reduce(
      (acc, item) => {
        const currentId = get(item, ['product', 'id'], null);

        if (currentId !== this.id) return acc;

        const currentQuantity = get(item, ['quantity'], 0);

        return acc + currentQuantity;
      },
      0
    );

    this.setQuantityInCart(quantity);
    this.checkCheckoutButton(quantity);
    this.checkBuyButton(quantity);

    if (this.checkLeftInStockMore(quantity)) this.viewState.productNoMoreOfStock();
  };

  initBuyButton = () => {
    const elBuyButton = dom.getElement(`#${PRODUCT_BUY_BUTTON_ID}`);

    dom.on(elBuyButton, 'click', this.handleAddToCart);
  };

  initQuantityInput = () => {
    const elQuantityInput = dom.getElement(`#${PRODUCT_QUANTITY_INPUT_ID}`);

    dom.on(elQuantityInput, 'change', this.handleChangeQuantity);
  };

  initOptions = async () => {
    const {
      options,
    } = this.state.product;

    const elOptionsContainer = dom.getElement(`#${PRODUCT_OPTIONS_ID}`);
    const formatAndUnits = await ecommerce.provider.getFormatAndUnits();

    options.forEach(({
      type,
      name,
      choices,
      defaultChoice,
      required,
    }) => {
      const productOption = new ProductOptions(
        type,
        name,
        choices,
        (price) => ecommerce.provider.formatPrice(price, formatAndUnits),
        defaultChoice,
        required
      );
      const defaultValue = get(choices[defaultChoice], ['text'], null);

      this.setOption(name, defaultValue);

      productOption.connect(
        elOptionsContainer,
        this.handleChangeOption(type)
      );

      if (this.checkOutOfStock()) this.hideProductOptions();
    });
  };

  setOption = (optionName, value, type) => {
    if (isNil(value)) return;

    if (type !== PRODUCT_OPTION_TYPES.CHECKBOX) {
      this.state.order.options[optionName] = value;

      return;
    }

    const option = get(this.state.order.options, [optionName], []);

    option.push(value);
    this.state.order.options[optionName] = option;
  };

  removeOption = (optionName, value) => {
    const options = this.state.order.options[optionName] || [];

    this.state.order.options[optionName] = remove(options, (o) => o !== value);
  };

  handleClickPreview = (index) => () => {
    const galleryItem = dom.getElement(`#product-carousel-${index}`);

    if (!galleryItem) return;

    dom.trigger(galleryItem, 'click');
  };

  handleChangeQuantity = (e) => {
    const { value } = e.target;
    const parseIntValue = Number.parseInt(value, 10);
    const mathAbsValue = Math.abs(value);

    if (parseIntValue < 0) {
      e.target.value = mathAbsValue;
      this.state.order.quantityToAdd = mathAbsValue;
    } else if (parseIntValue === 0) {
      e.target.value = 1;
      this.state.order.quantityToAdd = 1;
    } else {
      this.state.order.quantityToAdd = mathAbsValue;
    }
  };

  handleChangeOption = (type) => (e) => {
    const { name, value, checked = true } = e.target;

    if (!checked) {
      this.removeOption(name, value);
    } else {
      this.setOption(name, value, type);
    }

    this.updatePrice();
  };

  handleAddToCart = () => {
    this.addToCart();
  };

  addToCart = () => {
    const BUTTON_SPINNER_CLASS = 'product-page__button_spinner';
    const BUTTON_SUCCESS_CLASS = '_active';

    const elBuyButton = dom.getElement(`#${PRODUCT_BUY_BUTTON_ID}`);

    const productId = this.id;
    const quantityAddToCart = this.state.order.quantityToAdd;
    const { options } = this.state.order;

    dom.addClass(elBuyButton, BUTTON_SPINNER_CLASS);

    try {
      ecommerce.provider.cart.itemAdd(productId, quantityAddToCart, options, () => {
        dom.removeClass(elBuyButton, BUTTON_SPINNER_CLASS);
        dom.addClass(elBuyButton, BUTTON_SUCCESS_CLASS);
        setTimeout(() => {
          dom.removeClass(elBuyButton, BUTTON_SUCCESS_CLASS);
        }, 2000);
      });
    } catch {
      dom.removeClass(elBuyButton, BUTTON_SPINNER_CLASS);
    }
  };

  checkOutOfStock = () => this.state.product.isOutOfStock();

  checkBuyButton = (quantity) => {
    const NOT_IN_CART_LABEL_TEXT = 'Add to bag';
    const IN_CART_LABEL_TEXT = 'Add more';
    const IN_CART_BUTTON_CLASS = 'product-page__button_added';

    const elBuyButton = dom.getElement(`#${PRODUCT_BUY_BUTTON_ID}`);
    const elBuyButtonLabel = dom.getElement(`[id="${PRODUCT_BUY_BUTTON_ID}"] .buy-text`);

    if (quantity >= 1) {
      dom.addText(elBuyButtonLabel, IN_CART_LABEL_TEXT);
      dom.addClass(elBuyButton, IN_CART_BUTTON_CLASS);

      return;
    }

    dom.removeClass(elBuyButton, IN_CART_BUTTON_CLASS);
    dom.addText(elBuyButtonLabel, NOT_IN_CART_LABEL_TEXT);
  };

  // eslint-disable-next-line consistent-return
  checkCheckoutButton = (quantity) => {
    const elCheckoutButton = dom.getElement(`#${PRODUCT_CHECKOUT_BUTTON_ID}`);

    if (quantity >= 1 && !this.checkOutOfStock()) {
      return dom.updateStyle(
        elCheckoutButton,
        { display: 'block' }
      );
    }

    dom.updateStyle(elCheckoutButton, { display: 'none' });
  };

  showPreviewImage = (imageSrc) => {
    if (!imageSrc) return;

    const elPreviewImage = dom.getElement(`#${PRODUCT_PREVIEW_ID}`);

    dom.updateStyle(elPreviewImage, { display: 'flex' });
    elPreviewImage.setAttribute('src', imageSrc);
  };

  hidePreviewImage = () => {
    const elPreviewImage = dom.getElement(`#${PRODUCT_PREVIEW_ID}`);

    dom.updateStyle(elPreviewImage, { display: 'none' });
  };

  showNoPreview = () => {
    const container = dom.getElement(`#${PRODUCT_NO_PREVIEW_ID}`);

    dom.updateStyle(container, { display: 'flex' });
  };

  hideNoPreview = () => {
    const container = dom.getElement(`#${PRODUCT_NO_PREVIEW_ID}`);

    dom.updateStyle(container, { display: 'none' });
  };

  showRetry = () => {
    const container = dom.getElement(`#${PRODUCT_RETRY_ID}`);

    dom.updateStyle(container, { display: 'block' });
  };

  hideRetry = () => {
    const container = dom.getElement(`#${PRODUCT_RETRY_ID}`);

    dom.updateStyle(container, { display: 'none' });
  };

  showSpinner = () => {
    const container = dom.getElement(`#${PRODUCT_SPINNER_ID}`);

    dom.updateStyle(container, { display: 'block' });
  };

  hideSpinner = () => {
    const container = dom.getElement(`#${PRODUCT_SPINNER_ID}`);

    dom.updateStyle(container, { display: 'none' });
  };

  showSidebar = () => {
    dom.updateStyle(this.elSidebar, { display: 'block' });
    this.updateAnimation(this.elSidebar);
  };

  hideSidebar = () => {
    dom.updateStyle(this.elSidebar, { display: 'none' });
  };

  showGallery = () => {
    dom.updateStyle(this.elGallery, { display: 'block' });
    this.updateAnimation(this.elGallery);
  };

  hideGallery = () => {
    dom.updateStyle(this.elGallery, { display: 'none' });
  };

  showProductLabel = () => {
    const elStatusLabel = dom.getElement(`#${PRODUCT_STATUS_ID}`);

    if (!elStatusLabel) return;

    const badges = this.state.product.getBadges();

    if (!badges?.length) return;

    const badgesHtml = PRODUCT_BADGES_TEMPLATE(badges);

    dom.addHtml(elStatusLabel, badgesHtml.join(''));
  };

  showSoldOutButton = () => {
    const container = dom.getElement(`#${PRODUCT_SOLD_OUT_ID}`);

    dom.updateStyle(container, { display: 'block' });
  };

  hideSoldOutButton = () => {
    const container = dom.getElement(`#${PRODUCT_SOLD_OUT_ID}`);

    dom.updateStyle(container, { display: 'none' });
  };

  hideProductOptions = () => {
    const container = dom.getElement(`#${PRODUCT_OPTIONS_ID}`);

    dom.updateStyle(container, { display: 'none' });
  };

  hideCountInCart = () => {
    const container = dom.getElement(`#${PRODUCT_QUANTITY_IN_CART_ID}`);

    dom.updateStyle(container, { display: 'none' });
  };

  hideQuantityInput = () => {
    const container = dom.getElement('.product-page__quantity');

    dom.updateStyle(container, { display: 'none' });
  };

  hideBuyButton = () => {
    const container = dom.getElement(`#${PRODUCT_BUY_BUTTON_ID}`);

    dom.updateStyle(container, { display: 'none' });
  };

  hideCheckoutButton = () => {
    const container = dom.getElement(`#${PRODUCT_CHECKOUT_BUTTON_ID}`);

    dom.updateStyle(container, { display: 'none' });
  };

  viewState = {
    productLoading: () => {
      this.hideRetry();
      this.hideSidebar();
      this.hideGallery();
      this.showSpinner();
    },
    productLoadedError: () => {
      this.hideSpinner();
      this.hideSidebar();
      this.hideGallery();
      this.showRetry();
    },
    productLoadedSuccess: () => {
      this.hideRetry();
      this.hideSpinner();
      this.showSidebar();
      this.showGallery();
      this.showProductLabel();
    },
    productOutOfStock: () => {
      this.showGallery();
      this.showSidebar();
      this.showSoldOutButton();
      this.showProductLabel();

      this.hideSpinner();
      this.hideRetry();
      this.hideBuyButton();
      this.hideCheckoutButton();
      this.hideQuantityInput();
      this.hideCountInCart();
    },
    productNoMoreOfStock: () => {
      this.hideSpinner();
      this.hideRetry();
      this.hideBuyButton();
      this.hideSoldOutButton();
      this.hideQuantityInput();
      this.showGallery();
      this.showSidebar();
    },
    galleryWithoutPreview: () => {
      this.hidePreviewImage();
      this.showNoPreview();
    },
    galleryWithPreview: (src) => {
      this.hideNoPreview();
      this.showPreviewImage(src);
    },
  };
}

export default Product;
